import useDialog from 'modules/dialog/useDialog';
import {useShowPhoneNumber} from 'components/page/realEstate/common/hooks/useShowPhoneNumber';
import {useSubmitContactForm} from 'components/page/realEstate/common/hooks/contactForm/useSubmitContactForm';
import {ContactForm} from 'components/page/realEstate/common/contactForm/ContactForm';
import useHiddenRecaptcha from 'components/recaptcha/hooks/useHiddenRecaptcha';
import {IPhoneNumbers} from 'modules/realEstate/common/interfaces/IPhoneNumbers';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import {
    getGenerateLeadEvent,
    getPurchaseEvent, getOpenWebEvent, getClickPhoneNumberEvent, getAddToCartEvent,
} from 'modules/gtmEvents/helpers/events/realEstate/realEstateEventsHelper';
import {EFormState} from 'modules/gtmEvents/enums/EFormState';
import {EFormStep} from 'modules/gtmEvents/enums/EFormStep';
import {EMethod} from 'modules/gtmEvents/enums/EMethod';
import { formatWhatsappUrl, formatMessengerUrl } from 'components/page/realEstate/common/helpers/fomatSocials';
import {
    getResetEcommerceEvent
} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {useEffect} from 'react';
import { useTracker as useRtbTracker } from 'modules/analytics/rtb/hooks/util/visit/useTracker';
import { useLoginStore } from 'modules/stores/login/useLoginStore';

interface IReturn {
    phoneNumbers: IPhoneNumbers | undefined;
    onMainPhoneButtonClick: (userId: string) => void;
    onMailContactClick: () => void;
    onMessengerContactClick: (page: string) => void;
    onWhatsappContactClick: (phoneNumber: string, message?: string) => void;
    components: JSX.Element | null;
    isFetchingPhoneNumber: boolean;
    onWebClick: (web: string) => void;
}
export const useHeadlineContacts = (userId: string, userName: string, isAgent = false, parentUserName?: string): IReturn => {
    const {
        facebookPixel: { sendEvent: sendFacebookPixelEvent },
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const {
        rtbEventEmail,
        rtbEventMobile,
    } = useRtbTracker();

    const isUserLoggedIn = useLoginStore((store) => store.isUserLoggedIn);

    const {onSubmit: onSubmitEmailForm} = useSubmitContactForm(
        userId,
        () => {
            sendFacebookPixelEvent(['track', 'Contact']);
            gtmSendEvent(
                getGenerateLeadEvent(userId, userName, isAgent, EFormState.SUCCESS, EFormStep.STEP_2, EMethod.SQL_SEND_EMAIL, parentUserName, isUserLoggedIn),
                getResetEcommerceEvent(),
                getPurchaseEvent(userId, userName, parentUserName),
            );
            rtbEventEmail(userId);
            closeDialog();
        },
        () => {
            gtmSendEvent(getGenerateLeadEvent(userId, userName, isAgent, EFormState.ERROR, EFormStep.STEP_2, EMethod.SQL_SEND_EMAIL, parentUserName, isUserLoggedIn));
        },
        isAgent
    );
    const {component: contactFormDialog, show: showDialog, hide: closeDialog} = useDialog({
        title: isAgent ? 'Napíšte mi' : 'Napíšte nám',
        content: <ContactForm onSubmit={onSubmitEmailForm}/>
    });
    const {
        phoneNumbers,
        fetchPhoneApiCall,
        isFetching: isFetchingPhoneNumber,
    } = useShowPhoneNumber(userId, undefined, undefined, isAgent);
    const {component: recaptchaComponent, token: recaptchaToken, submitRecaptcha} = useHiddenRecaptcha(fetchPhoneApiCall);

    useEffect(() => {
        if (isUserLoggedIn === undefined) {
            return;
        }
        if (!isFetchingPhoneNumber && phoneNumbers) {

            sendFacebookPixelEvent(['track', 'AddToCart']);
            gtmSendEvent(
                getGenerateLeadEvent(userId, userName, isAgent, EFormState.SUCCESS, EFormStep.STEP_1, EMethod.SRL_SHOW_PHONE_NUMBER, parentUserName, isUserLoggedIn),
                getResetEcommerceEvent(),
                getPurchaseEvent(userId, userName, parentUserName, 0.2),
            );
            rtbEventMobile(userId);
        }
    }, [gtmSendEvent, isUserLoggedIn, isAgent, isFetchingPhoneNumber, parentUserName, phoneNumbers, rtbEventMobile, sendFacebookPixelEvent, userId, userName]);

    const onMainPhoneButtonClick = () => {
        if (!recaptchaToken) {
            submitRecaptcha();
        } else {
            gtmSendEvent(
                getClickPhoneNumberEvent(isAgent),
            );
        }
    };

    const onMailContactClick = (): void => {
        gtmSendEvent(
            getGenerateLeadEvent(userId, userName, isAgent, EFormState.PROGRESSED, EFormStep.STEP_1, EMethod.SQL_SEND_EMAIL, parentUserName, isUserLoggedIn),
            getAddToCartEvent(userId, userName, parentUserName, 1),
        );
        showDialog();
    };

    const onMessengerContactClick = (page: string): void => {
        sendFacebookPixelEvent(['track', 'Contact']);

        gtmSendEvent(
            getGenerateLeadEvent(userId, userName, isAgent, EFormState.SUCCESS, EFormStep.STEP_1, EMethod.SRL_OPEN_MESSENGER, parentUserName),
            getResetEcommerceEvent(),
            getPurchaseEvent(userId, userName, parentUserName, 0.2),
        );
        rtbEventEmail(userId);

        window.open(formatMessengerUrl(page));
    };

    const onWhatsappContactClick = (phoneNumber: string, message?: string): void => {
        phoneNumber = phoneNumber.replace(/[^\w\s]/gi, '').replace(/ /g, '');
        let url = formatWhatsappUrl(phoneNumber);
        if (message) {
            url += `?text=${encodeURI(message)}`;
        }

        gtmSendEvent(
            getGenerateLeadEvent(userId, userName, isAgent, EFormState.SUCCESS, EFormStep.STEP_1, EMethod.SRL_OPEN_WHATSAPP, parentUserName),
            getResetEcommerceEvent(),
            getPurchaseEvent(userId, userName, parentUserName, 0.2),
        );
        rtbEventEmail(userId);

        window.open(url);
    };

    const onWebClick = (web: string): void => {
        gtmSendEvent(
            getOpenWebEvent(userId, userName, isAgent, parentUserName),
        );
        window.open(web);
    };

    return {
        phoneNumbers,
        onMainPhoneButtonClick,
        onMailContactClick,
        onMessengerContactClick,
        onWhatsappContactClick,
        onWebClick,
        components: <>
            {contactFormDialog}
            {recaptchaComponent}
        </>,
        isFetchingPhoneNumber,
    };
};
