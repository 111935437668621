import React, {useState} from 'react';
import {
    StepInitialScreen
} from 'components/page/realEstate/agency/detail/components/partials/headline/mobile/partials/contacts/DrawerSteps/StepInitialScreen';
import {IAddress} from 'modules/realEstate/common/interfaces/IAddress';
import {StepPhoneNumbers} from 'components/page/realEstate/common/drawer/StepPhoneNumbers';
import useHiddenRecaptcha from 'components/recaptcha/hooks/useHiddenRecaptcha';
import {
    ILocationGps
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/ILocationGps';
import {
    IOpenHours
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/openHours/IOpenHours';
import {useSubmitContactForm} from 'components/page/realEstate/common/hooks/contactForm/useSubmitContactForm';
import {useContactFormComponents} from 'components/page/realEstate/common/hooks/contactForm/useContactFormComponents';
import {
    StepTextArea
} from 'components/page/realEstate/common/drawer/StepTextArea';
import {
    StepContactsAndSubmit
} from 'components/page/realEstate/common/drawer/StepContactsAndSubmit';
import {useShowPhoneNumber} from 'components/page/realEstate/common/hooks/useShowPhoneNumber';
import { EMobileSteps } from 'components/page/realEstate/common/enums/EMobileSteps';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import {
    getAddToCartEvent,
    getGenerateLeadEvent, getOpenWebEvent, getPurchaseEvent,
} from 'modules/gtmEvents/helpers/events/realEstate/realEstateEventsHelper';
import {EFormState} from 'modules/gtmEvents/enums/EFormState';
import {EFormStep} from 'modules/gtmEvents/enums/EFormStep';
import {EMethod} from 'modules/gtmEvents/enums/EMethod';
import { formatMessengerUrl, formatWhatsappUrl } from 'components/page/realEstate/common/helpers/fomatSocials';
import {
    getResetEcommerceEvent
} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import { useTracker as useRtbTracker } from 'modules/analytics/rtb/hooks/util/visit/useTracker';
import { useLoginStore } from 'modules/stores/login/useLoginStore';

interface IReturn {
    isOpen: boolean;
    stepDrawerContent: React.ReactNode;
    openDrawer: () => void,
    closeDrawer: () => void,
}

export const useStepDrawerMobile = (
    agencyId: string,
    agencyName: string,
    agencyAddress: IAddress,
    locationGps: ILocationGps,
    openHours?: IOpenHours,
    web?: string,
    whatsapp?: string,
    messenger?: string,
    parentAgencyName?: string,
): IReturn => {
    const [step, setStep] = useState<EMobileSteps>(null);
    const {
        facebookPixel: { sendEvent: sendFacebookPixelEvent },
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const {
        rtbEventEmail,
        rtbEventMobile,
    } = useRtbTracker();

    const isUserLoggedIn = useLoginStore((store) => store.isUserLoggedIn);

    const {
        phoneNumbers,
        fetchPhoneApiCall,
        isFetching: isFetchingPhoneNumber,
    } = useShowPhoneNumber(agencyId,
        () => {
            setStep(EMobileSteps.SHOW_PHONE_NUMBERS);
        },
        () => {
            gtmSendEvent(
                getGenerateLeadEvent(agencyId, agencyName, false, EFormState.ERROR, EFormStep.STEP_1, EMethod.SQL_CLICK_TO_CALL, parentAgencyName, isUserLoggedIn)
            );
        },
    );
    const {component: recaptchaInvisibleComponent, submitRecaptcha} = useHiddenRecaptcha(fetchPhoneApiCall);
    const {onSubmit: onSubmitEmailForm} = useSubmitContactForm(
        agencyId,
        () => {
            sendFacebookPixelEvent(['track', 'Contact']);

            gtmSendEvent(
                getGenerateLeadEvent(agencyId, agencyName, false, EFormState.SUCCESS, EFormStep.STEP_3, EMethod.SQL_SEND_EMAIL, parentAgencyName, isUserLoggedIn),
                getResetEcommerceEvent(),
                getPurchaseEvent(agencyId, agencyName, parentAgencyName),
            );
            rtbEventEmail(agencyId);

            closeDrawer();
        },
        () => {
            gtmSendEvent(getGenerateLeadEvent(agencyId, agencyName, false, EFormState.ERROR, EFormStep.STEP_3, EMethod.SQL_SEND_EMAIL, parentAgencyName, isUserLoggedIn));
        },
    );
    const {
        textAreaField,
        contactInputsMerged,
        agreeCheckbox,
        recaptcha,
        submitButton,
        handleSubmit,
        resetForm,
        textAreaValue
    } = useContactFormComponents(onSubmitEmailForm, 'Odoslať', false);

    const openDrawer = (): void => {
        setStep(EMobileSteps.DEFAULT_DRAWER_SCREEN);
        gtmSendEvent(
            getGenerateLeadEvent(agencyId, agencyName, false, EFormState.PROGRESSED, EFormStep.STEP_0, EMethod.SQL_CLICK_TO_CALL, parentAgencyName, isUserLoggedIn),
            getGenerateLeadEvent(agencyId, agencyName, false, EFormState.PROGRESSED, EFormStep.STEP_0, EMethod.SQL_SEND_EMAIL, parentAgencyName, isUserLoggedIn),
            getGenerateLeadEvent(agencyId, agencyName, false, EFormState.PROGRESSED, EFormStep.STEP_0, EMethod.SQL_SCHEDULE_VIEWING, parentAgencyName, isUserLoggedIn),
        );
    };

    const closeDrawer = (): void => {
        resetForm();
        setStep(null);
    };

    const getStep = () => {
        if (step === EMobileSteps.DEFAULT_DRAWER_SCREEN) {
            return <>
                <StepInitialScreen
                    onIconClick={() => {
                        setStep(null);
                    }}
                    agencyName={agencyName}
                    agencyAddress={agencyAddress}
                    locationGps={locationGps}
                    openHours={openHours}
                    web={web}
                    messenger={messenger}
                    whatsapp={whatsapp}
                    onPhoneButtonClick={() => {
                        submitRecaptcha();
                        gtmSendEvent(
                            getGenerateLeadEvent(agencyId, agencyName, false, EFormState.PROGRESSED, EFormStep.STEP_1, EMethod.SQL_CLICK_TO_CALL, parentAgencyName, isUserLoggedIn),
                            getAddToCartEvent(agencyId, agencyName, parentAgencyName, 1),
                        );
                        rtbEventMobile(agencyId);
                    }}
                    onEmailButtonClick={() => {
                        setStep(EMobileSteps.EMAIL_FORM_TEXTAREA);
                        gtmSendEvent(
                            getGenerateLeadEvent(agencyId, agencyName, false, EFormState.PROGRESSED, EFormStep.STEP_1, EMethod.SQL_SEND_EMAIL, parentAgencyName, isUserLoggedIn),
                            getAddToCartEvent(agencyId, agencyName, parentAgencyName, 1),
                        );
                    }}
                    isFetchingPhoneNumber={isFetchingPhoneNumber}
                    onMessengerClick={() => {
                        sendFacebookPixelEvent(['track', 'Contact']);

                        gtmSendEvent(
                            getGenerateLeadEvent(agencyId, agencyName, false, EFormState.SUCCESS, EFormStep.STEP_1, EMethod.SRL_OPEN_MESSENGER, parentAgencyName, isUserLoggedIn),
                            getResetEcommerceEvent(),
                            getPurchaseEvent(agencyId, agencyName, parentAgencyName),
                        );
                        rtbEventEmail(agencyId);

                        window.open(formatMessengerUrl(messenger));
                    }}
                    onWhatsappClick={() => {
                        gtmSendEvent(
                            getGenerateLeadEvent(agencyId, agencyName, false, EFormState.SUCCESS, EFormStep.STEP_1, EMethod.SRL_OPEN_WHATSAPP, parentAgencyName, isUserLoggedIn),
                            getResetEcommerceEvent(),
                            getPurchaseEvent(agencyId, agencyName, parentAgencyName),
                        );

                        window.open(formatWhatsappUrl(whatsapp));
                    }}
                    onWebClick={() => {
                        gtmSendEvent(
                            getOpenWebEvent(agencyId, agencyName, false, parentAgencyName),
                            getPurchaseEvent(agencyId, agencyName, parentAgencyName),
                        );

                        window.open(web);
                    }}
                />
                {recaptchaInvisibleComponent}
            </>;
        }
        if (step === EMobileSteps.SHOW_PHONE_NUMBERS) {
            return <StepPhoneNumbers
                phoneNumbers={phoneNumbers}
                callText={'Zavolajte nám'}
                onIconClick={() => {
                    setStep(EMobileSteps.DEFAULT_DRAWER_SCREEN);
                }}
                onMainPhoneClick={() => {
                    gtmSendEvent(
                        getGenerateLeadEvent(agencyId, agencyName, false, EFormState.SUCCESS, EFormStep.STEP_2, EMethod.SQL_CLICK_TO_CALL, parentAgencyName, isUserLoggedIn),
                        getResetEcommerceEvent(),
                        getPurchaseEvent(agencyId, agencyName, parentAgencyName),
                    );
                }}
                onOtherPhoneClick={() => {
                    gtmSendEvent(
                        getGenerateLeadEvent(agencyId, agencyName, false, EFormState.SUCCESS, EFormStep.STEP_2, EMethod.SQL_CLICK_TO_CALL, parentAgencyName, isUserLoggedIn),
                        getResetEcommerceEvent(),
                        getPurchaseEvent(agencyId, agencyName, parentAgencyName),
                    );
                }}
            />;
        }
        if (step === EMobileSteps.EMAIL_FORM_TEXTAREA) {
            return <StepTextArea
                textAreaField={textAreaField}
                onIconClick={() => {
                    setStep(EMobileSteps.DEFAULT_DRAWER_SCREEN);
                }}
                buttonText={'Pokračovať'}
                isNextDisabled={!textAreaValue || textAreaValue?.length === 0}
                onButtonClick={() => {
                    setStep(EMobileSteps.EMAIL_FORM_CONTACTS_INFO);
                    gtmSendEvent(getGenerateLeadEvent(agencyId, agencyName, false, EFormState.PROGRESSED, EFormStep.STEP_2, EMethod.SQL_SEND_EMAIL, parentAgencyName, isUserLoggedIn));
                }}
                callText={'Napíšte nám'}
            />;
        }
        if (step === EMobileSteps.EMAIL_FORM_CONTACTS_INFO) {
            return <StepContactsAndSubmit
                contactInputsMerged={contactInputsMerged}
                agreeCheckbox={agreeCheckbox}
                recaptcha={recaptcha}
                submitButton={submitButton}
                handleSubmit={handleSubmit}
                onIconClick={() => {
                    setStep(EMobileSteps.EMAIL_FORM_TEXTAREA);
                }}
            />;
        }
    };

    return {
        isOpen: step !== null,
        stepDrawerContent: getStep(),
        openDrawer,
        closeDrawer,
    };
};
