import {useEffect, useMemo} from 'react';
import {IBreadcrumb} from 'components/breadcrumbs/interfaces/IBreadcrumb';
import { useRealEstateStore } from 'modules/stores/page/realEstate/useRealEstateStore';

interface IReturn {
    breadcrumbs: IBreadcrumb[];
    setBreadcrumbs: (breadcrumbs: IBreadcrumb[]) => void;
}

const useBreadcrumbs = (apiBreadcrumbsData?: IBreadcrumb[]): IReturn => {
    const breadcrumbs = useRealEstateStore((store) => store.breadcrumbs);
    const setBreadcrumbs = useRealEstateStore((store) => store.setBreadcrumb);

    const data = useMemo(() => {
        return apiBreadcrumbsData ?? [];
    }, [apiBreadcrumbsData]);

    useEffect((): void => {
        setBreadcrumbs(data);
    }, [data, setBreadcrumbs]);

    return {
        breadcrumbs,
        setBreadcrumbs
    };
};

export default useBreadcrumbs;
